import React from "react"

import ErrorContainer from "../components/ErrorContainer"
import SEO from "../components/seo.js"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <ErrorContainer />
  </>
)

export default NotFoundPage
